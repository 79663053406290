import React from 'react'
import { graphql } from 'gatsby'
import GatsbyImage, { FluidObject } from 'gatsby-image'
import { Table, Row, Col, Nav } from 'react-bootstrap'
import { Link } from 'gatsby'

import { SquadraGiovanileQuery } from '../../types/graphql'

import {
  Blanket,
  BlanketContent,
  BlanketSubtitle,
  BlanketTitle,
} from '../../components/Blanket'
import { Container } from '../../components/Container'
import { Section } from '../../components/Section'
import { SEO } from '../../components/Seo'
import { Title } from '../../components/Title'

import * as classes from './squadra-giovanile.module.scss'

interface Props {
  data: SquadraGiovanileQuery
}

export default ({ data }: Props) => (
  <Blanket dark={false} noPadding>
    <SEO title={'Squadra'} />

    {data.team?.description && (
      <GatsbyImage
        fluid={
          data.team?.description?.localFile?.childImageSharp
            ?.fluid as FluidObject
        }
      />
    )}

    <Container>
      <BlanketTitle>{data.team?.name}</BlanketTitle>

      <BlanketSubtitle>{data.team?.year?.slug}</BlanketSubtitle>

      <BlanketContent>
        <Section>
          <Row>
            <Col xs={12} md={8}>
              <Title as={'h3'}>Giocatori</Title>

              <Table>
                <thead>
                  <tr>
                    <th>Numero</th>
                    <th>Nome</th>
                    <th>Altezza</th>
                    <th>Ruolo</th>
                    <th>Nascita</th>
                  </tr>
                </thead>

                <tbody>
                  {data.players.nodes.map(({ title, wordpress_id, info }) => (
                    <tr key={wordpress_id!}>
                      <td>{info?.Numero}</td>
                      <td>{title}</td>
                      <td>{info?.Altezza}</td>
                      <td>{info?.Ruolo}</td>
                      <td>{info?.Data_di_nascita}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>

            <Col xs={12} md={4}>
              <Nav className={'flex-column text-center'}>
                {data.years.nodes.map((node) => (
                  <Nav.Link
                    as={Link}
                    to={`/giovanili/${node.slug}/`}
                    key={node.id}
                  >
                    <Title as={'h2'}>{node.year.slug}</Title>
                  </Nav.Link>
                ))}
              </Nav>
            </Col>
          </Row>
        </Section>

        <Section>
          <Title as={'h3'}>Staff Tecnico</Title>

          <Row>
            {data.staff.nodes.map((node) => (
              <Col
                xs={12}
                md={2}
                className={classes.staff}
                key={node.wordpressId!}
              >
                <p>{node.info?.Ruolo}</p>

                <Title as={'h6'}>{node.title}</Title>
              </Col>
            ))}
          </Row>
        </Section>
      </BlanketContent>
    </Container>
  </Blanket>
)

export const query = graphql`
  query SquadraGiovanile($year: Int!, $team: Int!, $glob: String!) {
    team: wordpressWpSquadra(wordpress_id: { eq: $team }) {
      name
      slug
      description {
        localFile {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      year: parent_element {
        slug
      }
    }

    players: allWordpressWpGiocatore(filter: { squadra: { in: [$team] } }) {
      nodes {
        title
        slug
        wordpress_id
        squadra
        info {
          Ruolo
          Altezza
          Data_di_nascita
          Numero
        }
      }
    }

    staff: allWordpressWpStaff(
      filter: { squadra: { in: [$team] } }
      sort: { fields: [info___Ordinamento] }
    ) {
      nodes {
        title
        info {
          Ruolo
        }
        wordpressId: wordpress_id
      }
    }

    years: allWordpressWpSquadra(
      filter: {
        slug: { glob: $glob }
        parent_element: { wordpress_id: { nin: [null, $year] } }
      }
    ) {
      nodes {
        id
        slug
        year: parent_element {
          slug
        }
      }
    }
  }
`
